// components/product-templates/TrainingTemplateA.jsx

export default function TrainingTemplateB({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
}) {
    return (
        <div className=" bg-white rounded-xl ">
            <div className="border-2 border-6ixshooter-blue bg-white rounded-lg p-6 mb-4 shadow-lg hover:shadow-2xl transition-shadow duration-300 w-full max-w-md">

                <h2 className="text-2xl sm:text-3xl font-extrabold text-center text-gray-800 mb-3">
                    6ixShooter Virtual Training Session
                </h2>
                <p className="text-xl sm:text-2xl text-center font-semibold text-6ixshooter-blue">${product.price.toFixed(2)}</p>
                {/* Custom content for the course product */}
                <hr
                    className='w-full border-t-2 border-gray-200 my-4'
                />
                <div className="text-gray-700 text-sm sm:text-base mb-6 text-center">
                    <h3 className="text-lg sm:text-xl font-bold mb-4 text-gray-800">
                        Train 1-on-1 with the Best 3-Point Shooter on TikTok!
                    </h3>
                    <ul className="space-y-3">
                        <li className="font-medium">
                            🎯 <span className="font-semibold">1-Hour Virtual Training Session</span> — Work directly with Coach 6ixShooter and level up your game!
                        </li>
                        <li className="font-medium">
                            📝 <span className="font-semibold">Personalized Training Plan</span> — Tailored specifically for your goals and skill level.
                        </li>
                        <li className="font-medium">
                            📋 <span className="font-semibold">After-Session Notes</span> — Gain valuable insights and feedback directly from Coach 6ixShooter.
                        </li>
                    </ul>
                </div>
                {/* <p>VARIANT B</p>
            <p>VARIANT B</p>
            <p>VARIANT B</p>
                <p>VARIANT B</p> */}
            </div>
            <div className="flex justify-between mt-4">
                <button
                    onClick={onPrevious}
                    disabled={currentStep === 0}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50 shadow-md"
                >
                    Previous
                </button>
                <div className="space-x-4">
                    <button
                        onClick={onSkipProduct}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded shadow-md"
                    >
                        Skip
                    </button>
                    <button
                        onClick={onAddProduct}
                        className="bg-6ixshooter-blue text-white px-4 py-2 rounded hover:bg-6ixshooter-blue-hover shadow-md"
                    >
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    );
}