'use client';

import { useState, useEffect, useTransition } from 'react';
import { recordEvent } from '@/app/actions/analytics/recordEvent';

export default function VariantWrapper({ variant, childrenA, childrenB, visitId }) {
    const [isPending, startTransition] = useTransition();

    const handleClick = () => {
        startTransition(() => {
            recordEvent({
                visitId,
                eventType: 'button_click',
                eventData: { buttonName: variant === 'A' ? 'Variant A Button' : 'Variant B Button' },
                
            });
        });
    };

    return (
        <div>
            {variant === 'A' ? childrenA : childrenB}
            
        </div>
    );
}