'use client';

import VariantWrapper from '@/app/components/ab/VariantWrapper';
import CourseTemplateA from './CourseTemplateA';
import CourseTemplateB from './CourseTemplateB';
import TrainingTemplateA from './TrainingTemplateA';
import TrainingTemplateB from './TrainingTemplateB';
import SubscriptionTemplateA from './SubscriptionTemplateA';
import SubscriptionTemplateB from './SubscriptionTemplateB';
// import TrainingTemplate from './TrainingTemplate';
// import SubscriptionTemplate from './SubscriptionTemplate';

const ProductTemplates = {
    'course-product-id': (props) => (
        <VariantWrapper
            variant={props.variant}
            visitId={props.visitId}
            childrenA={<CourseTemplateA {...props} />}
            childrenB={<CourseTemplateB {...props} />}
        />
    ),
    'training-product-id': (props) => (
        <VariantWrapper
            variant={props.variant}
            visitId={props.visitId}
            childrenA={<TrainingTemplateA {...props} />}
            childrenB={<TrainingTemplateB {...props} />}
        />
    ),
    'subscription-product-id': (props) => (
        <VariantWrapper
            variant={props.variant}
            visitId={props.visitId}
            childrenA={<SubscriptionTemplateA {...props} />}
            childrenB={<SubscriptionTemplateB {...props} />}
        />
    ),
};

export default ProductTemplates;