'use client';

import { createContext, useContext } from 'react';

const TrackingContext = createContext({ sessionId: null, variant: null, visitId: null });

export function TrackingProviderContext({ value, children }) {
    return (
        <TrackingContext.Provider value={value}>
            {children}
        </TrackingContext.Provider>
    );
}

export function useTracking() {
    return useContext(TrackingContext);
}