// components/product-templates/SubscriptionTemplateA.jsx

export default function SubscriptionTemplateA({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
}) {
    return (
        <div className="rounded-2xl">
            <div className="bg-white shadow-xl rounded-lg w-full max-w-lg p-7">
                <h2 className="text-3xl font-semibold text-center text-gray-700 mb-4">
                    6ix Golden Membership 🏀🥇
                </h2>
                <p className="text-2xl text-center font-bold text-6ixshooter-green mb-6">
                    ${product.price.toFixed(2)} / month
                </p>
                <hr className="my-4 border-indigo-200" />

                <div className="text-6ixshooter-bg-black text-base mb-6 text-left space-y-4">
                    <ul className="space-y-4 text-gray-700 leading-relaxed">
                        <li className="flex items-start">
                            <span className="mr-2">🚨</span>
                            <div>
                                <span className="font-semibold">24/7 Chat w/6ixShooter</span><br />
                                Instant support and tips anytime.
                            </div>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2">✨</span>
                            <div>
                                <span className="font-semibold">Weekly Live Coaching</span><br />
                                Tune in to refine your skills.
                            </div>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2">🗒️</span>
                            <div>
                                <span className="font-semibold">Video Reviews</span><br />
                                Submit clips for quick feedback.
                            </div>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2">🤐</span>
                            <div>
                                <span className="font-semibold">Exclusive Discord Channels</span><br />
                                Access to:<br />
                                <span className="ml-4 block">1&#41; Fix Your Form ✅</span>
                                <span className="ml-4 block">2&#41; Chat with 6ix 🎯</span>
                                <span className="ml-4 block">3&#41; Coaching Livestream 🔥</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <p className="text-gray-800 mb-6">
                    Join a community of driven players and level up your game with elite guidance.
                </p>
            </div>

            <div className="flex justify-between mt-4">
                <button
                    onClick={onPrevious}
                    disabled={currentStep === 0}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50 shadow-md hover:bg-gray-400 transition"
                >
                    Previous
                </button>
                <div className="space-x-4">
                    <button
                        onClick={onSkipProduct}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded shadow-md hover:bg-gray-400 transition"
                    >
                        Skip
                    </button>
                    <button
                        onClick={onAddProduct}
                        className="bg-6ixshooter-blue text-white px-4 py-2 rounded shadow-md hover:bg-6ixshooter-blue-hover transition"
                    >
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    );
}