// components/product-templates/CourseTemplateA.jsx

import Image from "next/image";

export default function CourseTemplateA({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
}) {
    return (
        <div className=" bg-white rounded-xl ">

            <h2 className="text-2xl sm:text-3xl font-extrabold text-center text-gray-800 mb-3">
                Join The 6ixShooter Academy
            </h2>
            <h3 className='mb-6 sm:mb-10 text-xl sm:text-2xl font-bold text-6ixshooter-green text-center'>50% off - Code &quot;6IX&quot;</h3>
            <div className="relative border rounded-xl shadow-sm transition-transform duration-300 max-h-[28rem] overflow-y-auto">

                <div className="flex flex-col items-center p-4 sm:p-6 lg:p-8 rounded-lg max-w-3xl mx-auto">
                    {/* Product Name */}
                    <h3 className="text-2xl sm:text-3xl font-extrabold text-gray-800 mb-4 sm:mb-6 text-center">
                        65+ TRAINING GUIDES!
                    </h3>

                    {/* Product Description */}
                    <p className="text-base sm:text-lg text-gray-600 text-center mb-6 sm:mb-8 px-4 sm:px-6">
                        This training course compiles everything Seth McCoy (6ixShooter) has learned
                        over the years to become an Elite Shooter & Elite-Level Player!
                    </p>

                    {/* Features List */}
                    <ul className="space-y-4 sm:space-y-5 text-center">
                        <li className="text-base sm:text-lg text-gray-700">
                            🚀 <span className="font-semibold">Track Your Progress Automatically</span> — Stay on top of your game effortlessly!
                        </li>
                        <li className="text-base sm:text-lg text-gray-700">
                            🏀 <span className="font-semibold">Exclusive 6ixShooter Workouts</span> — Unlock the drills that deliver elite results!
                        </li>
                        <li className="text-base sm:text-lg text-gray-700">
                            🔥 <span className="font-semibold">Everything You Need to Dominate the Court</span> — Become the player everyone fears!
                        </li>
                        <li className="text-base sm:text-lg text-gray-700">
                            💪 <span className="font-semibold">Complete Strength Training Program</span> — Build the power to outperform your competition!
                        </li>
                        <li className="text-base sm:text-lg text-gray-700">
                            🌟 <span className="font-semibold">Tailored for All Levels</span> — Beginner, Intermediate, or Advanced, we&apos;ve got you covered!
                        </li>
                    </ul>

                    {/* Product Image */}
                    <div className="relative mt-6 sm:mt-8">
                        <Image
                            width={300}
                            height={400}
                            src={product.image || "/course_photos/image-3.jpeg"}
                            alt={product.name}
                            className="shadow-md rounded-lg"
                        />
                    </div>
                </div>
            </div>

                <div className="mt-4 sm:mt-8 flex justify-center">
                    <button
                        onClick={onNext}
                        className={`w-full sm:w-2/3 px-6 py-3 bg-6ixshooter-blue hover:bg-6ixshooter-blue-hover
                text-white font-semibold rounded-full shadow-md 
                transition
        duration-300
        ease-in-out
        transform
        active:scale-95
                focus:outline-none flex items-center justify-center
            `}
                    >
                        {/* Next Icon */}
                        <span className="mr-2 text-sm sm:text-base">Learn More</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 sm:h-6 sm:w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>
                </div>
        </div>
    );
}