// components/FunnelModal.jsx

'use client';

import { PRODUCT_TYPES } from '@/utils/constants';
import { startTransition, useEffect, useState } from 'react';
import PasswordForm from './PasswordForm';
import PaymentForm from './PaymentForm';
import ProductStep from './ProductStep';
import SchedulingForm from './SchedulingForm';

// Import Stripe libraries
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { checkUserPasswordSet } from '@/app/actions/checkUserPasswordSet';
import PaymentSuccess from './PaymentSuccess';
import { recordEvent } from '../actions/analytics/recordEvent';
import { set } from 'zod';
import { XMarkIcon } from '@heroicons/react/24/solid';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export default function FunnelModal({ visitId, onClose, steps, trainingFunnel, variant, isAdmin }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [funnelSteps, setFunnelSteps] = useState(steps);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [showScheduling, setShowScheduling] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [trainingProduct, setTrainingProduct] = useState(null);
    const [funnelOpenTime, setFunnelOpenTime] = useState(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false); // New state for confirm modal

    useEffect(() => {

        const courseProduct = steps.find(
            (step) => step.product.productType === PRODUCT_TYPES.COURSE
        )?.product;

        if (courseProduct) {
            setSelectedProducts([courseProduct]);
        } else {
            const trainingProduct = steps.find(
                (step) => step.product.productType === PRODUCT_TYPES.VIRTUAL_TRAINING
            )?.product;
            setSelectedProducts([trainingProduct]);
            setTrainingProduct(trainingProduct);
        }
        recordFunnelSection(
            funnelSteps[currentStep].sectionName || `step_${currentStep}`
        )
    }, []);

    useEffect(() => {
        setFunnelOpenTime(Date.now());
        startTransition(() => {
            recordEvent({
                visitId,
                eventType: 'funnel_open',
                eventData: { variant },
                productType: steps[0].product.productType
            });
        });
    }, [visitId]);

    // Record event when user reaches the payment screen
    useEffect(() => {
        if (showPayment) {
            startTransition(() => {
                recordEvent({
                    visitId,
                    eventType: 'payment_screen',
                    eventData: { variant },
                    productType: selectedProducts[0].productType
                });
            });
        }
    }, [showPayment]);

    // Function to record funnel section events
    const recordFunnelSection = (sectionName) => {
        startTransition(() => {
            recordEvent({
                visitId,
                eventType: `funnel_section_${sectionName}`,
                eventData: { variant },
                productType: currentProduct.productType
            });
        });
    };

    if (funnelSteps.length === 0) {
        return null; // Or a loading indicator
    }

    const totalSteps = funnelSteps.length;

    const handleNext = () => {
        const nextStep = currentStep + 1;
        if (nextStep < totalSteps) {
            setCurrentStep(nextStep);
            // Record event for the next funnel section
            const nextSectionName = funnelSteps[nextStep].sectionName || `step_${nextStep}`;
            recordFunnelSection(nextSectionName);
        } else {
            // Check if trainingProduct is selected
            if (trainingProduct) {
                setShowScheduling(true);
            } else {
                setShowPayment(true);
            }
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleAddProduct = () => {
        const currentProduct = funnelSteps[currentStep].product;

        // Check if the current product is already in the selected products
        if (!selectedProducts.some(product => product.id === currentProduct.id)) {
            setSelectedProducts([...selectedProducts, currentProduct]);
            // Check if the current product is a training session
            if (currentProduct.productType === PRODUCT_TYPES.VIRTUAL_TRAINING) {
                recordFunnelSection(
                    `step_2`
                );
                setTrainingProduct(currentProduct);
                setShowScheduling(true);
            }
        }
        handleNext();
    };

    const handleSkipProduct = () => {
        const currentProduct = funnelSteps[currentStep].product;
        const updatedProducts = selectedProducts.filter(
            (product) => product.id !== currentProduct.id
        );
        if (currentProduct.productType === PRODUCT_TYPES.VIRTUAL_TRAINING) {
            setTrainingProduct(null);
        }
        setSelectedProducts(updatedProducts);
        handleNext();
    };

    const currentProduct = funnelSteps[currentStep].product;

    const handlePaymentSuccess = async ({ email }) => {
        let funnelDuration = Date.now() - funnelOpenTime;
        //convert funnelDuration to a formated time hr:min:sec 
        funnelDuration = new Date(funnelDuration).toISOString().substr(11, 8);
        // After purchase completes (assuming payment done):
        startTransition(() => {
            recordEvent({
                visitId,
                eventType: 'funnel_conversion',
                eventData: {
                    funnelDuration,
                    variant,
                    products: selectedProducts.map((p) => p.id)
                },
                productType: selectedProducts[0].productType
            });
        });
        setUserEmail(email);
        //check if user already exists
        const user = await checkUserPasswordSet(email);
        if (user) {
            setShowPasswordForm(false);
            setShowSuccess(true);
        } else {
            setShowPasswordForm(true);
            setShowSuccess(false);
        }
    };

    const handleClose = () => {
        setIsConfirmOpen(true);
    };

    const confirmClose = () => {
        setIsConfirmOpen(false);
        onClose();
    };

    const cancelClose = () => {
        setIsConfirmOpen(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 pt-8 w-full max-w-md relative">
                <button
                    onClick={handleClose} // Modify onClick to handleClose
                    className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-500 rounded-full p-1 transition-colors duration-200"
                    aria-label="Close Payment Success Modal"
                >
                    <XMarkIcon className="h-6 w-6" />
                </button>

                {
                    isAdmin && (
                        <div className="text-center">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                                Admin View - Variant {variant}
                            </h2>
                        </div>
                    )
                }

                {showSuccess ? (
                    <PaymentSuccess isOpen={showSuccess} onClose={onClose} />
                ) : showPasswordForm ? (
                    <PasswordForm email={userEmail} onSuccess={onClose} />
                ) : showScheduling ? (
                    <SchedulingForm
                        onSuccess={() => {
                            setShowScheduling(false);
                            setShowPayment(true);
                        }}
                        onBack={() => {
                            setShowScheduling(false);
                            // Optionally, navigate back to the previous product step
                            if (currentStep > 0) {
                                setCurrentStep(currentStep - 1);
                                setSelectedProducts();
                            } else {
                                setShowPayment(true);
                            }
                        }}
                    />
                ) : showPayment ? (
                    <Elements stripe={stripePromise}>
                        <PaymentForm
                            selectedProducts={selectedProducts}
                            onSuccess={handlePaymentSuccess}
                        />
                    </Elements>
                ) : (
                    <ProductStep
                        variant={variant}
                        visitId={visitId}
                        trainingFunnel={trainingFunnel}
                        product={currentProduct}
                        onAddProduct={handleAddProduct}
                        onSkipProduct={handleSkipProduct}
                        onPrevious={handlePrevious}
                        onNext={handleNext}
                        currentStep={currentStep}
                        totalSteps={totalSteps}
                    />
                )}

                {isConfirmOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50
                    ">
                        <div className="bg-white rounded-lg p-6 pt-8 w-full max-w-md text-center relative">
                            {
                                showPasswordForm && (
                                    <h2
                                        className='font-bold text-2xl mb-4'
                                    >
                                        WARNING!
                                    </h2>
                                )
                            }
                            <p
                                className={`text-lg
                                    ${showPasswordForm ? ' text-6ixshooter-red' : ' text-gray-700'}
                                    `}
                            >

                                {
                                    showPasswordForm ? (
                                        `Not creating your password will prevent you from accessing the 6ixShooter Academy. 
                                        Are you sure you want to close the funnel?`
                                    ) : (
                                        'Are you sure you want to close this?'
                                    )
                                }

                            </p>
                            <div className="mt-4 flex justify-end space-x-2">
                                <button onClick={cancelClose} className="px-4 py-2 bg-gray-200 rounded">
                                    Cancel
                                </button>
                                <button onClick={confirmClose} className="px-4 py-2 bg-6ixshooter-red text-white rounded">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
