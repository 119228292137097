// components/PaymentSuccess.jsx

'use client';

import { CheckCircleIcon } from '@heroicons/react/24/solid';

const PaymentSuccess = ({ onClose }) => {
    return (
        <div className="flex items-center justify-center  bg-gray-100">
            <div className="bg-white rounded-xl shadow-lg p-8 sm:p-12 text-center">
                {/* Success Icon */}
                <CheckCircleIcon className="mx-auto h-16 w-16 text-6ixshooter-green" />

                {/* Success Message */}
                <h1 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800">
                    Payment Successful!
                </h1>
                <p className="mt-2 text-gray-600">
                    Thank you for your payment. Your access to the 6ixShooter Academy has been activated.
                </p>

                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="mt-6 px-6 py-2 bg-6ixshooter-green text-white font-semibold rounded-full hover:bg-6ixshooter-green-hover"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default PaymentSuccess;