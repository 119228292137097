// components/ProductStep.jsx

import DefaultProductTemplate from './product-template/DefaultProductTemplate';
import ProductTemplates from './product-template/index'

export default function ProductStep({
    product,
    onAddProduct,
    onPrevious,
    currentStep,
    totalSteps,
    onSkipProduct,
    onNext,
    trainingFunnel,
    variant,
    visitId
}) {
    // Choose a template based on productType or productId
    const ProductTemplate = ProductTemplates[product.id] || DefaultProductTemplate;
    return (
        <ProductTemplate
            variant={variant}
            visitId={visitId}
            trainingFunnel={trainingFunnel}
            product={product}
            onAddProduct={onAddProduct}
            onPrevious={onPrevious}
            currentStep={currentStep}
            totalSteps={totalSteps}
            onNext={onNext}
            onSkipProduct={onSkipProduct}
        />
    );
}