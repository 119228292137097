'use client';

import { useEffect, useState, useTransition } from 'react';
import { getOrAssignVariant } from '@/app/actions/ab/getOrAssignVariant';
import { recordVisit } from '@/app/actions/analytics/recordVisit';
import { recordEvent } from '@/app/actions/analytics/recordEvent';
import { TrackingProviderContext } from './TrackingContext';

export default function TrackingProvider({ pageUrl, children }) {
    const [isPending, startTransition] = useTransition();
    const [sessionId, setSessionId] = useState(null);
    const [variant, setVariant] = useState(null);
    const [visitId, setVisitId] = useState(null);

    useEffect(() => {
        let sid = localStorage.getItem('sessionId');
        if (!sid) {
            sid = crypto.randomUUID();
            localStorage.setItem('sessionId', sid);
        }
        setSessionId(sid);

        let v = localStorage.getItem('variant');
        if (!v) {
            // No variant yet, assign via server action
            startTransition(async () => {
                const assigned = await getOrAssignVariant(sid);
                localStorage.setItem('variant', assigned);
                setVariant(assigned);

                // Record visit now that we have variant
                const vid = await recordVisit({ sessionId: sid, variant: assigned, pageUrl });
                setVisitId(vid);

                // optional: record a page_view event here if desired
                // await recordEvent({ sessionId: sid, eventType: 'page_view', eventData: { startTime: Date.now() }});
            });
        } else {
            setVariant(v);
            startTransition(async () => {
                const vid = await recordVisit({ sessionId: sid, variant: v, pageUrl });
                setVisitId(vid);
                // await recordEvent(... page_view ...)
            });
        }
    }, [pageUrl]);

    if (!sessionId || !variant || !visitId) return null; // Wait until we have all data

    return (
        <TrackingProviderContext value={{ sessionId, variant, visitId }}>
            {children}
        </TrackingProviderContext>
    );
}